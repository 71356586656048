import React, { useState } from 'react';
import { Link } from 'gatsby';
import ArchiveYear from '../Components/ArchiveYear';

const ArchiveContainer = props => {

  const generateIssueArticleList = (issueList, articleList) => {
    let articleIssueList = {}

    if(!!issueList){
      issueList.forEach( issue => {
        articleIssueList[issue.id] = issue
        articleIssueList[issue.id].articles = []
      })
    }
    if(!!articleList){
      articleList.forEach( article => {
        // if this article has an issue associated with it
        // put it in the right issue (otherwise ignore it)
        //
        if (!!articleIssueList[article.issue]){
          articleIssueList[article.issue].articles.push(article)
        }
      })
    }
    return articleIssueList
  }

  const generateBackArticles = (articleArray, highlight, lowlight) => {

    let linkStyles = {
      backgroundColor: `#${highlight}`,
      color: `#${lowlight}`,
      textDecorationColor: `#${lowlight}`
    }

    return articleArray.map((article, index)=>{

      let slugDate = article.pubDate.split('-').join('/')
      let slugText = article.slug
      let articleSlug = `${slugDate}/${slugText}`


      return (!!article.slug ?
          <Link to={`/${articleSlug}`} >
            <div style={linkStyles} className="backIssueLink">{article.title} by {article.authorName}</div>
          </Link>
        : <div></div>
      )
    })
  }

  const generateBackIssues = (archiveYear) => {
    let issueArticleList = generateIssueArticleList(props.issues, props.articles)
    let monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    if(!props.issues && !props.articles){
      return;
    }
    return props.issues.map((issue, index) => {
      let backIssueDate = new Date(issue.pubDate)
      let backIssueYear = backIssueDate.getFullYear()
      if( backIssueYear===archiveYear ){
        let backIssueStyles = { backgroundColor : `#${issue.lowlightColor}`, color : `#${issue.highlightColor}`}
        let backIssueMonth = monthArray[backIssueDate.getMonth()]
        let backIssueDateSlug = `${backIssueMonth} ${backIssueYear}`
        let issueArticles = ""
        if (issueArticleList[issue.id]){
          issueArticles = issueArticleList[issue.id].articles
        } 
        return (
          <div key={`${archiveYear}-${issue.slug}`} className = "backIssue" style= {backIssueStyles}>
            <Link to={`/issue/${issue.slug}`}><div>{backIssueDateSlug} — {issue.topic}</div></Link>
            <div>{issueArticles.length > 0 ? generateBackArticles(issueArticles, issue.highlightColor, issue.lowlightColor) : <div>empty</div>}</div>
          </div>
          )
      }
    })
  }

  // may make sense to lift all these "open" functions 
  // to the top along with the state to check if
  // we're hiding the last batch of articles
  // and turn off the scrollbar (and vice versa)

  return (
    <div className = "archiveContainer">
      <ArchiveYear year = "2024">
        {generateBackIssues(2024)}
      </ArchiveYear>
      <ArchiveYear year = "2023">
        {generateBackIssues(2023)}
      </ArchiveYear>
      <ArchiveYear year = "2022">
        {generateBackIssues(2022)}
      </ArchiveYear>
      <ArchiveYear year = "2021">
        {generateBackIssues(2021)}
      </ArchiveYear>
      <ArchiveYear year = "2020">
        {generateBackIssues(2020)}
      </ArchiveYear>
      <ArchiveYear year = "2019">
        {generateBackIssues(2019)}
      </ArchiveYear>
      <ArchiveYear year = "2018">
        {generateBackIssues(2018)}
      </ArchiveYear>
    </div>
  )
}

export default ArchiveContainer;
